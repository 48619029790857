import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import configs from './../../../configs/config';
import Page from '../../../models/pageTranslated';


export default class LandingPageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.page ||  new Page()),
            errors: [],
        };
        this.state.editMode = this.state.id !== null;   //HAY QUE  USARLO::: 
        this.submitCallback = this.props.onSubmit;
        this.translateCallback = this.props.onSubmitTranslate;

    }
    componentDidMount(){
        console.log(`mounted ${this.props.page}`);   
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('pageName', this.state.pageName);
        //vamos a guardarlo en base64
        //let dataHtml = new Buffer.from( this.state.bodyHtml,'utf-8').toString("base64");

        formData.append('bodyHtml', this.state.bodyHtml);
        
       // if (typeof this.submitCallback === "function") {
       //     this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 
       // }

        if (typeof this.translateCallback === "function"){
            console.log(`we will send ${this.state.pageId}`);
              formData.append('pageId', this.state.pageId);  //la pagina padre de la traduccion. 
              formData.append('language', this.state.language);
              console.log("Sending" + this.state.bodyHtml);

              this.translateCallback(formData,this.state.id,this.state.language);
        }
        else
        if (typeof this.submitCallback === "function") {
            if (this.state.id)
                this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 
            else
                this.submitCallback(formData);  //si el Id entonces nuevo. 
        }


    }

    decodeToString = (data) => {
        try {
        let buff = new Buffer.from(data, 'base64');
        return buff.toString('utf-8');
        }
        catch (e) 
        {
            return '<body></body>';
        }
    }  

    isBase64 = (encodedString) => {
        var regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return regexBase64.test(encodedString);   // return TRUE if its base64 string.
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }
    render() {
        var dataIn= !this.isBase64(this.state.bodyHtml) ? this.state.bodyHtml : this.decodeToString(this.state.bodyHtml) ;

        return (
            <>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">
                        <div className="modal-content">
                            
                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">
                                            <div className="col-12 col-md-12 col-lg-12 my-3">
                                            <CKEditor editor={ClassicEditor} rows="3" data={this.state.bodyHtml} onChange={(_, editor) => {
                                                        const data = editor.getData();
                                                        this.setState({ bodyHtml: data });
                                                    }} />

                                        {
                                            dataIn &&
                                            <Editor
                                                apiKey={configs.TINYMCE_API_KEY} 
                                                initialValue={dataIn}
                                                init={{
                                                height: 500,
                                                images_upload_url: `${configs.URL_API_BASE}/uploadImages/image`,
                                                automatic_uploads: true,
                                                block_unsupported_drop: true,
                                                menubar: false,
                                                content_css : ["https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css",
                                                "//api.bienesraicestibesa.mx/themes/tequila/css/theme.css",
                                                "//api.bienesraicestibesa.mx/themes/tequila/css/template.css"],
                                                content_js:[ "//ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js",
                                                "//cdnjs.cloudflare.com/ajax/libs/popper.js/1.13.0/umd/popper.min.js",
                                                "//maxcdn.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js",
                                                "//api.bienesraicestibesa.mx/themes/tequila/js/scripts.js"],
                                                content_style:" #mainSlide {  background-image: url('https://images.unsplash.com/photo-1503756043224-101dab0dcb30?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4bd62d181f78bd2f85ab23a677acd6f4&auto=format&fit=crop&w=1600&q=80'); background-repeat: no-repeat; background-size: cover; background-position: center top; }",
                                                menu: {
                                                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                                                    insert: { title: 'Insert', items: 'link image' },
                                                    view: { title: 'View', items: 'visualaid' },
                                                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' }
                                                },
                                                plugins: "image paste code",
                                                paste_data_images: true,
                                                toolbar:
                                                    'undo redo | formatselect | table link bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | image code',
                                                }}
                                                onEditorChange={(content) => {
                                                    console.log(content);
                                                    const data = content; 
                                                    this.setState({ bodyHtml: data });                                                    
                                                }}
                                            />
                                        }
                                            
                                            </div>

                                         </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/landingpages'}>CANCELAR</button>

                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}