const RULE_FUNCTIONS = {
    minValue(value, minValue){
        return value >= minValue;
    },

    maxValue(value, maxValue){
        return value <= maxValue;
    },

    required(value){
        return value !== undefined && value !== '' && value != null;
    },

    allowNull(rule){
        return true || rule;
    },

    maxLen(value, maxSize){
        return !value || value.length <= maxSize;
    },

    minLen(value, maxSize){
        return !value || value.length >= maxSize;
    },
  
    fileSize(file, size){
        console.log(file.size, size,file.size <= size );
        return  file.size <= size;
    }
}


export default {

    validateState(state){
        return Object.keys(state)
        .map(k => ({ruleDesc: this.getRule(k), key: k}))
        .filter(k => k.ruleDesc && !k.ruleDesc.rule(state[k.key]))
        .map(k => k.key);
    },
    
    validateField(name, value){
        let ruleDesc =  this.getRule(name);
        return !ruleDesc || ruleDesc.rule(value);
    },

    getRule(field){
        return this.rules.filter(r => r.fields.indexOf(field) !== -1)[0];
    },

    rules: [
        {
            fields:['es_name', 'en_name', 'address', 'es_slug', 'en_slug', 'es_logoAlt', 'en_logoAlt'],
            rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 200)
        },
        {
            fields:['es_mainDescription', 'en_mainDescription'],
            rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 10000)
        },
        {
            fields:['es_metaDescription', 'en_metaDescription'],
            rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 160)
        },
        {
            fields:['es_keywords', 'en_keywords'],
            rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 255)
        },
        {
            fields:['price', 'currencyId', 'typeId', 'categoryId', 'totalSize', 'frontSize', 'backgroundSize',
        'stateId', 'cityId', 'subcategoryId'],
            rule: v => RULE_FUNCTIONS.minValue(v, 1)
        },
        {
            fields:['fieldForm'],
            rule: v => RULE_FUNCTIONS.minValue(v, 1) && RULE_FUNCTIONS.maxValue(v,2)
        },
        {
            fields:['zip'],
            rule: v =>  RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.minLen(v, 5) && RULE_FUNCTIONS.maxLen(v,5)
        },
        {
            fields:['latitude'],
            rule: v => RULE_FUNCTIONS.minValue(v, -90) && RULE_FUNCTIONS.maxValue(v,90)
        },
        {
            fields:['longitude'],
            rule: v => RULE_FUNCTIONS.minValue(v, -180) && RULE_FUNCTIONS.maxValue(v,180)
        },
        {
            fields:['pdf'],
            rule: v => RULE_FUNCTIONS.allowNull(RULE_FUNCTIONS.fileSize(v,5242880))
        },
        {
            fields:['logo'],
            rule: v => RULE_FUNCTIONS.allowNull(RULE_FUNCTIONS.fileSize(v,1572864))
        },
    ]
}