import React, { Component } from 'react';
import Property from '../../../models/property'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import Switch from "react-switch"
import ImageUploader from "react-images-upload"
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AmenityCheckBox from "./amenity-checkbox";
import InputFiles from 'react-input-files';
import validation from '../utils/form-validation';

import { propertiesService, propertyTypesService, placesService, categoriesService, currenciesService, amenitiesService,feedMapperService } from '../../../services/tibesa.api'
import "react-tabs/style/react-tabs.css"
import classNames from 'classnames';
import Swal from 'sweetalert2'


const TradeTypes = ["Undefined","Renta", "Venta", "Remate Bancario"];

export default class PropertyForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...(props.property || new Property()),
            c_subcategories: [],
            c_cities: [],
            c_types: [],
            c_amenities: [],
            c_currencies: [],
            c_categories: [],
            c_states: [],
            c_mapIds:[],
            c_viva:[],
            c_lamudi:[],   
            vivaMapCode:0,  //codigo de viva. 
            lamudiMapCode:0,
            localGallery: [],
            errors: []
        };

        this.state.propertyTitle = this.state.es_name || 'Crear nueva propiedad';
        this.state.serverGallery = this.state.galleryThumbs;
        this.state.editMode = this.state.id != null;

        this.submitCallback = this.props.onSubmit;
    }

    componentDidMount() {
        this.getCatalogs();
    }

    getCatalogs() {
        let p_types = propertyTypesService.getAll(),
            p_amenities = amenitiesService.getAll(),
            p_currencies = currenciesService.getAll(),
            p_categories = categoriesService.getAll(),
            p_states = placesService.getStates(),
            p_mapIds = propertiesService.getMapIds(this.state.id),
            p_viva = feedMapperService.getMapperCodes("VIV"),   //de Vivaanuncios  esta aparte y no es parte de propiedades aunque se guarde con el mismo 
            p_lamudi = feedMapperService.getMapperCodes("LMA")   //de Lamudi  esta aparte y no es parte de propiedades aunque se guarde con el mismo 


        let p_subcategories = p_categories.then(c_categories => {
            if (c_categories && c_categories.length > 0) {
                return categoriesService.getSubcategoriesOf(this.state.categoryId || c_categories[0].id_category);
            }
            else {
                return Promise.resolve([]);
            }
        });

        let p_cities = p_states.then(c_states => {
            if (c_states && c_states.length > 0) {
                return placesService.getCitiesOfState(this.state.stateId || c_states[0].id_estado);
            }
            else {
                return Promise.resolve([]);
            }
        });

        Promise.all([p_types, p_amenities, p_currencies, p_categories, p_subcategories, p_states, p_cities, p_viva, p_lamudi, p_mapIds])
            .then(([c_types, c_amenities, c_currencies, c_categories, c_subcategories, c_states, c_cities, c_viva, c_lamudi, c_mapIds]) => {
                let tmpViva = c_mapIds.filter(x => x.source=="VIV");
                let tmpLamudi = c_mapIds.filter(x => x.source=="LMA");

             //   let tmp2 = c_mapIds.filter(x => x.source=="MLB");

                this.setState({
                    c_types,
                    c_amenities: c_amenities.map(a => ({ id: a.id_amenity, value: a.name, isChecked: this.state.amenities.some(s_a => s_a.id == a.id_amenity) })),
                    c_currencies,
                    c_categories,
                    c_subcategories: c_subcategories.filter(s => s.status == 1 || (this.state.editMode && s.idSubCategory == this.state.subcategoryId)),
                    c_states,
                    c_cities,
                    c_viva,
                    c_lamudi,
                    vivaMapCode : tmpViva && tmpViva.length>0 ? tmpViva[0].mapId : 0,
                    lamudiMapCode : tmpLamudi && tmpLamudi.length>0 ? tmpLamudi[0].mapId : 0,
                    categoryId: this.state.categoryId || c_categories[0].id_category,
                    subcategoryId: this.state.subcategoryId || c_subcategories[0].idSubCategory,
                    stateId: this.state.stateId || c_states[0].id_estado,
                    cityId: this.state.cityId || c_cities[0].id_municipio
                });
            });

    }

    investmentPossibilityCheck = () => {
        this.setState({ investmentPossibility: !this.state.investmentPossibility });
    }

    validateInput = (name, value) => {
        let validationResult = validation.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
        if (value && name == 'stateId') {
            placesService.getCitiesOfState(value)
                .then(results => {
                    this.setState({
                        c_cities: results,
                        cityId: (results[0] || {}).id_municipio
                    });
                });
        }
        else if (value && name == 'categoryId') {
            categoriesService.getSubcategoriesOf(value)
                .then(results => {
                    this.setState({
                        c_subcategories: results,
                        subcategoryId: (results[0] || {}).idSubCategory
                    },
                        () => {
                            this.validateInput('subcategoryId', this.state.subcategoryId);
                        }
                    );
                })
        }
    }

    handleSwitches = (value, _, id) => {
        this.setState({ [id]: value });
    }

    handleCheckAmenity = (event) => {
        let amenities = this.state.c_amenities
        amenities.forEach(amenity => {
            if (amenity.value === event.target.value)
                amenity.isChecked = event.target.checked
        })
        this.setState({ c_amenities: amenities });
    }

    handleChangePdf = (files, name) => {
        if (files.length > 0) {
            if (this.validateInput('pdf', files[0])) {
                this.setState({
                    [name]: files[0],
                    [`${name}Url`]: files[0].name
                });
            }
            else {
                Swal.fire('Archivo muy grande!', 'el tamaño máximo es 5 MB', 'error');
            }
        }
    }

    handleChangeLogo = (files) => {
        if (files.length > 0) {
            console.log(files[0]);
            if (this.validateInput('logo', files[0])) {
                this.setState({
                    logo: files[0],
                    logoPreview: URL.createObjectURL(files[0])
                });
            }
            else {
                Swal.fire('Imagen muy grande!', 'el tamaño máximo es 1.5 MB', 'error');
            }

        }
    }

    handleImageDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            localGallery: pictureFiles,
            galleryThumbs: pictureDataURLs
        });
    }

    handleSubmit = (event) => {
        console.log(event.target.name);
        event.preventDefault();

        let errors = validation.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo en todas las pestañas`, 'error');
            return false;
        }
        console.log("comming 1");

        let formData = new FormData();
        formData.append("property_type", this.state.typeId);
        formData.append("categoryId", this.state.categoryId);
        formData.append("idSubCategory", this.state.subcategoryId);
        formData.append("totalsize", this.state.totalSize);
        formData.append("front", this.state.frontSize);
        formData.append("background", this.state.backgroundSize);
        formData.append("fieldForm", this.state.fieldForm);
        formData.append("price", this.state.price);
        formData.append("currencyCode", this.state.currencyId);
        formData.append("isInvertionC", this.state.investmentPossibility);
        formData.append("habsize", this.state.size);
        formData.append("bedrooms", this.state.bedrooms);
        formData.append("bathrooms", this.state.bathrooms);
        formData.append("levels", this.state.levels);

        formData.append("tradeType", this.state.tradeType);

        formData.append("latitud", this.state.latitude);
        formData.append("longitud", this.state.longitude);
        formData.append("stateId", this.state.stateId);
        formData.append("city", this.state.cityId);
        formData.append("cp", this.state.zip);
        formData.append("address", this.state.address);
        formData.append("name_en", this.state.en_name);
//TODO Debido a que aqui marca eeror por el contenido lo transformaremos a Base64 y de ahi la API que lo vuelva html. 

let dataHtml = new Buffer.from( this.state.en_mainDescription,'utf-8').toString("base64");

        formData.append("descriptionEN", dataHtml);

        formData.append("alte", this.state.en_logoAlt);
        formData.append("keywordsEN", this.state.en_keywords);
        formData.append("metaEn", this.state.en_metaDescription);
        formData.append("slugEN", this.state.en_slug);
        formData.append("pdfUrli", this.state.en_pdfUrl);
        //calculado. 
        var mapIds = "";  //debera concatenar todos los combos. 
        if (this.state.vivaMapCode>0) mapIds += this.state.vivaMapCode; 
       // if (this.state.mlbMapCode>0) mapIds += this.state.mlbMapCode;
        if (this.state.lamudiMapCode>0) mapIds += (mapIds!="") ? "," + this.state.lamudiMapCode :  this.state.lamudiMapCode; 
        
        console.log(dataHtml);
        
        if (mapIds) formData.append("mapIds",mapIds);
        if (this.state.en_pdf) formData.append("pdfi", this.state.en_pdf);
        if (this.state.en_rentTerms) formData.append("rentTermsEN", this.state.en_rentTerms);
        if (this.state.en_petsTerms) formData.append("petsTermsEN", this.state.en_petsTerms);
        formData.append("name_es", this.state.es_name);
        
//TODO Debido a que aqui marca eeror por el contenido lo transformaremos a Base64 y de ahi la API que lo vuelva html. 
let dataHtml2 = new Buffer.from( this.state.es_mainDescription,'utf-8').toString("base64");
console.log(dataHtml2);

        formData.append("descriptionEs", dataHtml2);
        formData.append("alt", this.state.es_logoAlt);
        formData.append("keywords", this.state.es_keywords);
        formData.append("meta", this.state.es_metaDescription);
        formData.append("slug", this.state.es_slug);
        formData.append("pdfUrl", this.state.es_pdfUrl);
        if (this.state.es_pdf) formData.append("pdf", this.state.es_pdf);
        if (this.state.es_rentTerms) formData.append("rentTerms", this.state.es_rentTerms);
        if (this.state.es_petsTerms) formData.append("petsTerms", this.state.es_petsTerms);

        if (this.state.logo) {
            formData.append('pic_0', this.state.logo);
        }

        if (this.state.localGallery.length > 0) {
            this.state.localGallery.forEach((image, i) => formData.append(`pic_${i + 1}`, image));
        }

        let imagesToDelete = this.state.serverGallery
            .filter(i => this.state.galleryThumbs.indexOf(i) === -1)
            .map(i => i.split('/').pop())
            .join('@');

        if (imagesToDelete) {
            formData.append(`imagestodelete`, imagesToDelete);
        }

        let amenities = this.state.c_amenities.filter(a => a.isChecked).map(a => a.id).join(',');
        formData.append("amenities", amenities);

        if (this.state.investmentPossibility) {
            formData.append("isInvertionTEn", this.state.en_investmentTerms)
            formData.append("isInvertionT", this.state.es_investmentTerms);
        }

        if (this.state.videoUrl) formData.append("videoUrl", this.state.videoUrl);
        formData.append("prioridad", this.state.isHighlighted);
        formData.append("status", this.state.isActive ? 1 : 2);

        if(this.state.virtualTourKey){
            formData.append("virtualTourKey", this.state.virtualTourKey);
            formData.append("virtualTourType", this.state.virtualTourType);
        }

        if (typeof this.submitCallback === "function") {
            this.submitCallback(formData);
        }
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    render() {

        let investmentDescriptionRow;

        if (this.state.investmentPossibility) {
            investmentDescriptionRow = <div className="col-12 col-lg-6 col-xl-6">
                <div className="form-group">
                    <Tabs>
                        <TabList>
                            <Tab>Términos de la inversión (Español)</Tab>
                            <Tab>Términos de la inversión (Inglés)</Tab>
                        </TabList>
                        <TabPanel>
                            <textarea name="es_investmentTerms" value={this.state.es_investmentTerms} onChange={this.handleChange}
                                className="form-control" placeholder="Términos de la inversión (Español)" rows="3"></textarea>
                        </TabPanel>
                        <TabPanel>
                            <textarea name="en_investmentTerms" value={this.state.en_investmentTerms} onChange={this.handleChange}
                                className="form-control" placeholder="Términos de la inversión (Inglés)" rows="3"></textarea>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>

        }
        else {
            investmentDescriptionRow = '';
        }

        return (
            <form className="property-form" onSubmit={this.handleSubmit} autoComplete="off">
        
                <div className="row">
                    <div className="col-12">
                        <Tabs>
                            <TabList>
                                <Tab><h5>Inf. Básica</h5></Tab>
                                <Tab><h5>Características</h5></Tab>
                                <Tab><h5>Ubicación</h5></Tab>
                                <Tab><h5>SEO</h5></Tab>
                                <Tab><h5>Multimedia</h5></Tab>
                                <Tab><h5>Términos</h5></Tab>
                                <Tab><h5>Mapeos</h5></Tab>
                            </TabList>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                        <h3>{this.state.propertyTitle}</h3>
                                        <hr />
                                        <h4>Información básica</h4>
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                                        <div className="form-group row align-items-center">
                                            <label className="col-2"> Nombre:{" "}<span className="text-red">*</span></label>
                                            <div className="col-10">
                                                <Tabs>
                                                    <TabList>
                                                        <Tab><small>Español</small></Tab>
                                                        <Tab><small>Inglés</small></Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <input name="es_name" type="text" placeholder="Nombre de la propiedad (ES)"
                                                            value={this.state.es_name} onChange={this.handleChange}
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('es_name') })} />
                                                        <div className="invalid-feedback">Nombre de la propiedad (ES) requerido - máximo 200 caracteres.</div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <input name="en_name" type="text" placeholder="Nombre de la propiedad (EN)"
                                                            value={this.state.en_name} onChange={this.handleChange}
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('en_name') })} />
                                                        <div className="invalid-feedback">Nombre de la propiedad (EN) requerido - máximo 200 caracteres.</div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2 col-lg-2 col-xl-3">
                                        <div className="form-group">
                                            <label> Precio:{" "}<span className="text-red">*</span></label>
                                            <input name="price" type="number" placeholder="####"
                                                value={this.state.price} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('price') })} />
                                            <div className="invalid-feedback">Precio debe ser mayor a 0.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label> Moneda{" "} <span className="text-red">*</span></label>
                                            <select name="currencyId" value={this.state.currencyId} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('currencyId') })}>
                                                <option value="" disabled> Moneda </option>
                                                {this.state.c_currencies.map((item, pos) => (<option key={pos} value={item.id_Currency}> {item.currencyCode} </option>))}
                                            </select>
                                            <div className="invalid-feedback">Selecciona moneda.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Tipo de  Propiedad{" "} <span className="text-red">*</span></label>
                                            <select name="typeId" value={this.state.typeId} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_types.map((item, pos) => (<option key={pos} value={item.id_propertyType}> {item.name} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Categoría de {" "} <span className="d-inline"> prop. </span> <span className="text-red"> * </span> </label>
                                            <select name="categoryId" value={this.state.categoryId} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_categories.map((item, pos) => (<option key={pos} value={item.id_category}> {item.nombre} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Subcategoría de{" "} <span className="d-inline"> prop.</span>{" "}<span className="text-red">*</span></label>
                                            <select name="subcategoryId" value={this.state.subcategoryId} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('subcategoryId') })}>
                                                {this.state.c_subcategories.map((item, pos) => (<option key={pos} value={item.idSubCategory}> {item.name} </option>))}
                                            </select>
                                            <div className="invalid-feedback">Selecciona subcategoría.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="custom-control d-flex align-items-center pl-0">
                                            <label className="form-check-label mr-3" htmlFor="priorizar">Propiedad destacada:</label>
                                            <Switch onChange={this.handleSwitches} checked={this.state.isHighlighted} id="isHighlighted" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        {
                                            this.state.editMode ?
                                                <div className="custom-control d-flex align-items-center pl-0">
                                                    <label className="form-check-label mr-3" htmlFor="priorizar">Propiedad activa:</label>
                                                    <Switch onChange={this.handleSwitches} checked={this.state.isActive} id="isActive" />
                                                </div>
                                                : ''

                                        }
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Propiedad En ?</label>
                                            <select name="tradeType" value={this.state.tradeType} onChange={this.handleChange}
                                                className="form-control">
                                                {TradeTypes.map((item, pos) => (<option key={pos} value={pos}> {item} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-4">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Descripción (Español)<span className="text-red">*</span></Tab>
                                                <Tab>Descripción (Inglés)<span className="text-red">*</span></Tab>
                                            </TabList>
                                            <TabPanel>
                                                <div className={classNames({ 'is-invalid': this.hasError('es_mainDescription') })}>
                                                    <CKEditor editor={ClassicEditor} data={this.state.es_mainDescription} onChange={(_, editor) => {
                                                        const data = editor.getData();
                                                        this.setState({ es_mainDescription: data });
                                                        this.validateInput('es_mainDescription', data);
                                                    }} />
                                                </div>
                                                <div className="invalid-feedback">Descripción (Español) es requerida - máximo 10,000 caracteres.</div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className={classNames({ 'is-invalid': this.hasError('en_mainDescription') })}>
                                                    <CKEditor editor={ClassicEditor} data={this.state.en_mainDescription} onChange={(_, editor) => {
                                                        const data = editor.getData();
                                                        this.setState({ en_mainDescription: data });
                                                        this.validateInput('en_mainDescription', data);
                                                    }} />
                                                </div>
                                                <div className="invalid-feedback">Descripción (Inglés) es requerida - máximo 10,000 caracteres.</div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Terreno</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Forma del Terreno{" "}<span className="text-red">*</span></label>
                                            <select name="fieldForm" value={this.state.fieldForm} onChange={this.handleChange}
                                                className="form-control">
                                                <option value="1">Regular</option>
                                                <option value="2">Irregular</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Superficie{" "}<span className="text-red">*</span></label>
                                            <div className={classNames('input-group', { 'is-invalid': this.hasError('totalSize') })} >
                                                <input name="totalSize" type="number" placeholder="###"
                                                    value={this.state.totalSize} onChange={this.handleChange}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('totalSize') })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text"> m<sup>2</sup></span>
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">Superficie m<sup>2</sup> debe ser mayor que 0</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Frente {" "} <span className="text-red">*</span></label>
                                            <div className={classNames('input-group', { 'is-invalid': this.hasError('frontSize') })} >
                                                <input name="frontSize" value={this.state.frontSize} onChange={this.handleChange}
                                                    type="number" placeholder="###"
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('frontSize') })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text"> m</span>
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">Frente m debe ser mayor que 0</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Fondo{" "}<span className="text-red">*</span></label>
                                            <div className={classNames('input-group', { 'is-invalid': this.hasError('backgroundSize') })} >
                                                <input name="backgroundSize" value={this.state.backgroundSize} onChange={this.handleChange}
                                                    type="number" placeholder="###"
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('backgroundSize') })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text"> m</span>
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">Fondo m debe ser mayor que 0</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Amenidades</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-12">
                                        <div className="row" >
                                            {
                                                this.state.c_amenities.map((amenity) => {
                                                    return (<AmenityCheckBox {...amenity} key={amenity.id} handleCheckElement={this.handleCheckAmenity} />)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Ubicación</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Domicilio<span className="text-red">*</span></label>
                                            <input name="address" type="text" placeholder="Calle #, colonia - (ES)"
                                                value={this.state.address} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('address') })} />
                                            <div className="invalid-feedback">Domicilio requerido - máximo 200 caracteres.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Estado{" "}<span className="text-red">*</span></label>
                                            <select name="stateId" value={this.state.stateId} onChange={this.handleChange}
                                                className="form-control">
                                                <option value="" disabled> Selecciona Estado</option>
                                                {this.state.c_states.map((item, pos) => (<option key={pos} value={item.id_estado}>{item.estado}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Ciudad{" "}<span className="text-red">*</span></label>
                                            <select name="cityId" value={this.state.cityId} onChange={this.handleChange}
                                                className="form-control">
                                                <option value="" disabled>Selecciona Ciudad</option>
                                                {this.state.c_cities.map((item, pos) => (<option key={pos} value={item.id_municipio}>{item.nombre_municipio}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Código Postal{" "}<span className="text-red">*</span></label>
                                            <input name="zip" type="text" placeholder="#####"
                                                value={this.state.zip} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('zip') })} />
                                            <div className="invalid-feedback">Domicilio requerido - exactamente 5 caracteres.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Latitud{" "}<span className="text-red">*</span></label>
                                            <input name="latitude" type="number" placeholder="##.##"
                                                value={this.state.latitude} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('latitude') })} />
                                            <div className="invalid-feedback">Latitud requerida - valor entre -90 y 90.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Longitud{" "}<span className="text-red">*</span></label>
                                            <input name="longitude" type="number" placeholder="##.##"
                                                value={this.state.longitude} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('longitude') })} />
                                            <div className="invalid-feedback">Longitud requerida - valor entre -180 y 180.</div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>SEO</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-12 col-lg-10 col-xl-10">
                                        <div className="form-group">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>URL amigable (Español){" "} <span className="text-red">*</span></Tab>
                                                    <Tab>URL amigable (Inglés){" "} <span className="text-red">*</span></Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <div className={classNames('input-group', { 'is-invalid': this.hasError('es_slug') })} >
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">https://www.bienesraicestibesa.mx/propiedad/##/</div>
                                                        </div>
                                                        <input name="es_slug" value={this.state.es_slug} onChange={this.handleChange}
                                                            type="text" placeholder="url-amigable"
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('es_slug') })} />
                                                    </div>
                                                    <div className="invalid-feedback">URL amigable (Español) requerida - máximo 200 caracteres.</div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className={classNames('input-group', { 'is-invalid': this.hasError('en_slug') })}>
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">https://www.bienesraicestibesa.com/property/##/</div>
                                                        </div>
                                                        <input name="en_slug" value={this.state.en_slug} onChange={this.handleChange}
                                                            type="text" placeholder="url-amigable"
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('en_slug') })} />
                                                    </div>
                                                    <div className="invalid-feedback">URL amigable (Inglés) requerida - máximo 200 caracteres.</div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Metadescripción (Español){" "}<span className="text-red">*</span></Tab>
                                                    <Tab>Metadescripción (Inglés){" "}<span className="text-red">*</span></Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <textarea name="es_metaDescription" placeholder="Metadescripción (Español)" rows="3"
                                                        value={this.state.es_metaDescription} onChange={this.handleChange}
                                                        className={classNames('form-control', { 'is-invalid': this.hasError('es_metaDescription') })}></textarea>
                                                    <div className="invalid-feedback">Metadescripción (Español) requerida - máximo 160 caracteres.</div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <textarea name="en_metaDescription" placeholder="Metadescripción (Inglés)" rows="3"
                                                        value={this.state.en_metaDescription} onChange={this.handleChange}
                                                        className={classNames('form-control', { 'is-invalid': this.hasError('en_metaDescription') })}></textarea>
                                                    <div className="invalid-feedback">Metadescripción (Inglés) requerida - máximo 160 caracteres.</div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Keywords (Español){" "}<span className="text-red">*</span></Tab>
                                                    <Tab>Keywords (Inglés){" "}<span className="text-red">*</span></Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <textarea name="es_keywords" placeholder="Keywords (Español)" rows="3"
                                                        value={this.state.es_keywords} onChange={this.handleChange}
                                                        className={classNames('form-control', { 'is-invalid': this.hasError('es_keywords') })}></textarea>
                                                    <div className="invalid-feedback">Keywords (Español) requerida - máximo 255 caracteres.</div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <textarea name="en_keywords" placeholder="Keywords (Inglés)" rows="3"
                                                        value={this.state.en_keywords} onChange={this.handleChange}
                                                        className={classNames('form-control', { 'is-invalid': this.hasError('en_keywords') })}></textarea>
                                                    <div className="invalid-feedback">Keywords (Inglés) requerida - máximo 255 caracteres.</div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Multimedia</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-3 col-xl-3">
                                                <div className="form-group">
                                                    <label>Logo de la propiedad{" "} <span className="text-red">*</span></label>
                                                    <InputFiles onChange={this.handleChangeLogo} accept="image/x-png,image/gif,image/jpeg">

                                                        {
                                                            this.state.logoPreview ?
                                                                <>
                                                                    <button className="btn btn-secondary btn-sm mx-1" type="button">Cambiar</button>
                                                                    <img width={'auto'} height={100} mode='fit' src={this.state.logoPreview} />
                                                                </>
                                                                :
                                                                <button type="button">Seleccionar</button>
                                                        }
                                                    </InputFiles>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                                                <div className="form-group">
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab><small>Alt Logo (Español)<span className="text-red">*</span></small></Tab>
                                                            <Tab><small>Alt Logo (Inglés)<span className="text-red">*</span></small></Tab>
                                                        </TabList>
                                                        <TabPanel>
                                                            <input name="es_logoAlt" type="text" placeholder="alt (Español)"
                                                                value={this.state.es_logoAlt} onChange={this.handleChange}
                                                                className={classNames('form-control', { 'is-invalid': this.hasError('es_logoAlt') })} />
                                                            <div className="invalid-feedback">Alt de Logo (Español) requerido - máximo 200 caracteres.</div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <input name="en_logoAlt" type="text" placeholder="alt (Inglés)"
                                                                value={this.state.en_logoAlt} onChange={this.handleChange}
                                                                className={classNames('form-control', { 'is-invalid': this.hasError('en_logoAlt') })} />
                                                            <div className="invalid-feedback">Alt de Logo (Inglés) requerido - máximo 200 caracteres.</div>
                                                        </TabPanel>
                                                    </Tabs>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                                                <div className="form-group">
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab><small>PDF (Español)<span className="text-red">*</span></small></Tab>
                                                            <Tab><small>PDF (Inglés)<span className="text-red">*</span></small></Tab>
                                                        </TabList>
                                                        <TabPanel>
                                                            <InputFiles onChange={files => this.handleChangePdf(files, 'es_pdf')} accept="application/pdf">
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <button className="btn btn-secondary" type="button">Seleccionar</button>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.es_pdfUrl} placeholder="PDF (Español)" readOnly />
                                                                </div>
                                                            </InputFiles>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <InputFiles onChange={files => this.handleChangePdf(files, 'en_pdf')} accept="application/pdf" name="">
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <button className="btn btn-secondary" type="button">Seleccionar</button>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.en_pdfUrl} placeholder="PDF (Inglés)" readOnly />
                                                                </div>
                                                            </InputFiles>
                                                        </TabPanel>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <label> URL de Video Youtube{" "}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">https://www.youtube.com/watch?v=</div>
                                                        </div>
                                                        <input name="videoUrl" value={this.state.videoUrl} onChange={this.handleChange}
                                                            type="text" className="form-control" placeholder="MIDFPeZ85hg" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <label>Tour 360 / virtual</label>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text">Tipo:</div>
                                                                </div>
                                                                <select name="virtualTourType" value={this.state.virtualTourType} onChange={this.handleChange}
                                                                    className="form-control">
                                                                    <option value="4">Cupix</option>
                                                                    <option value="5">My360</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text">ID:</div>
                                                                </div>
                                                                <input name="virtualTourKey" value={this.state.virtualTourKey} onChange={this.handleChange}
                                                                    type="text" className="form-control" placeholder="Ej. rDm543o7" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Galería</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            buttonText="Subir imagenes"
                                            label="Tamaño máximo: 1.5mb, formatos:  png, jpg, jpeg, gif"
                                            defaultImages={this.state.galleryThumbs}
                                            onChange={this.handleImageDrop}
                                            imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                                            maxFileSize={1572864}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Términos</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <input type="checkbox" name="investmentPossibility" checked={this.state.investmentPossibility} onChange={this.investmentPossibilityCheck} className="form-check-input posible-inversion" id="posibleinversion" />
                                        <label className="form-check-label posibleinversion mb-3" htmlFor="posibleinversion">Posible inversión?</label>
                                    </div>
                                    {investmentDescriptionRow}
                                </div>
                                <div className="row pb-3">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Términos de renta (Español)</Tab>
                                                    <Tab>Términos de renta (Inglés)</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <textarea name="es_rentTerms" value={this.state.es_rentTerms} onChange={this.handleChange}
                                                        className="form-control" placeholder="Keywords (Español)" rows="3"></textarea>
                                                </TabPanel>
                                                <TabPanel>
                                                    <textarea name="en_rentTerms" value={this.state.en_rentTerms} onChange={this.handleChange}
                                                        className="form-control" placeholder="Keywords (Inglés)" rows="3"></textarea>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Términos de mascotas (Español)</Tab>
                                                    <Tab>Términos de mascotas (Inglés)</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <textarea name="es_petsTerms" value={this.state.es_petsTerms} onChange={this.handleChange}
                                                        className="form-control" placeholder="Keywords (Español)" rows="3"></textarea>
                                                </TabPanel>
                                                <TabPanel>
                                                    <textarea name="en_petsTerms" value={this.state.en_petsTerms} onChange={this.handleChange}
                                                        className="form-control" placeholder="Keywords (Inglés)" rows="3"></textarea>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <h5>Mapeo de {this.state.propertyTitle}</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Categoria en Viva Anuncios</label>
                                            <select name="vivaMapCode" value={this.state.vivaMapCode} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_viva.map((item, pos) => (<option key={pos} value={item.id}> {item.name} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label>Categoria en Lamudi Anuncios</label>
                                            <select name="lamudiMapCode" value={this.state.lamudiMapCode} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_lamudi.map((item, pos) => (<option key={pos} value={item.id}> {item.name} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12">
                        <hr />
                        <button type="button" className="btn float-right" onClick={() => window.location.href = '/properties'}>CANCELAR</button>
                        <button type="submit" className="btn btn-secondary float-right">GUARDAR</button>
                    </div>
                </div>
            </form>
        )
    }
}